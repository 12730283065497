import React from "react";
import {Helmet} from "react-helmet";

// import images
import favicon from "../images/favicon.ico";

const browser = typeof window !== "undefined" && window;

const NotFound = () => {
    return (
        browser && <main>
            <Helmet>
                <link rel="icon" href={favicon}/>
                <title>404 Not Found</title>
                <meta name="robots" content="noindex,nofollow"/>
            </Helmet>
            404 Not Found
        </main>
    )
};

export default NotFound;